// auth page styles V2.0 ////////////////////////////////////////////////////


// authentication common-section
.authentication-common-section{
    padding: 40px 30px 50px 100px;
    height: 100%;
    background: url('https://assets.glowingbud.com/roamsim-web-folder/Sign-in/auth-left-mobile-img-2.png');
    background-size: 85%;
    background-position: bottom center;
    background-repeat: no-repeat;

    @media (max-height: 650px){
        background-size: contain;
        // background-position: bottom right;
    }

    @media (min-width: 1500px){
        background-size: 66%;
    }

    @media (max-width: 1199px){
        background: none;
        padding: 35px 50px;
    }

    @media (max-width: 767px){
        padding: 20px 15px;
    }

    .auth-logo-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .auth-logo{
            height: 50px;

            @media (max-width: 1199px){
                height: 40px;
            }

            @media (max-width: 767px){
                display: none;
            }
        }

        .auth-logo-mob{
            height: 20px;
            @media (min-width: 767px){
                display: none;
            }
            @media screen and (max-width:575px) {
                height:30px;
            }
        }

        .go-home-btn{
            border-radius: 100px;
            background: var(--color-primary);
            color: var(--color-white);
            height: 40px;
            padding: 8px 48px;
            font-family: var(--sf-pro-medium);
            font-size: 16px;
            line-height: 32px;
            letter-spacing: -0.32px;
            display: flex;
            align-items: center;

            @media (max-width: 1199px){
                font-size: 15px;
                line-height: 32px;
                letter-spacing: -0.36px;
            }
            @media screen and (max-width:376px) {
                font-size: 12px !important;
                padding: 8px 25px !important; 
            }
        }
    }
    
}




// Authentication right styles ////////////////////////////////////////////////////

.authentication-right-inner{
    height: 100%;
}

.authentication-right-signup{
    padding: 80px 40px 40px 40px;

    @media (max-width: 1199px){
        padding: 80px 120px;
    }

    @media (max-width: 767px){
        padding: 45px 15px;
    }
}

.authentication-right-signin{
    padding: 80px 115px 40px 115px;

    @media (max-width: 1400px){
        padding: 80px 80px;
    }

    @media (max-width: 1199px){
        padding: 80px 120px;
    }

    @media (max-width: 767px){
        padding: 45px 15px;
    }
}

.auth-signup-overflow{
    max-height: 280px;
    overflow: auto;

    @media (min-height: 775px){
        min-height: 380px;
    }

    @media (min-height: 850px){
        min-height: 490px;
    }

    @media screen and (max-width: 1199px), screen and (min-height: 775px){
        max-height: unset;
        min-height: unset;
        overflow: unset;
    }

    @media (max-width: 1199px){
        max-height: unset;
    }
}

.auth-right-head{
    color: var(--color-white);
    font-family: var(--g-font-semibold);
    font-size: 34px;
    line-height: 64px;
    letter-spacing: -0.68px;
    max-width: 490px;
    margin-bottom: 40px;

    @media (max-width: 1199px){
        font-size: 42px;
        line-height: 52px;
        letter-spacing: -0.84px;
        max-width: 100%;
    }

    @media (max-width: 767px){
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.52px;
    }

    .auth-right-head-mobile{
        @media (max-width: 1199px){
            margin-top: 14px;
        }
        @media (max-width: 767px){
            margin-top: 10px;
        }
    }

    .auth-right-head-mobile{
        @media (max-width: 1199px){
            font-size: 36px;
            line-height: 42px;
            letter-spacing: -0.72px;
            display: block;
        }

        @media (max-width: 767px){
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.36px;
            display: block;
        }
    }
}

.auth-subhead{
    color: var(--color-dark);
    font-family: var(--g-font-semibold);
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.48px;
    margin-bottom: 6px;
}

.auth-subtext{
    color: var(--color-dark-disabled);
    font-family: var(--sf-pro-medium);
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.32px;
}


.auth-page-link-text{
    .page-link{
        color: var(--color-primary);
        font-family: var(--sf-pro-medium);
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.28px;
        text-decoration: none;
    }
}


// Auth form ////////

.auth-form-container{
    padding: 25px 20px;
    border-radius: 15px;

    @media (max-width: 1199px){
        padding: 25px 30px;
    }

    @media (max-width: 767px){
        padding: 18px 22px;
    }

    .mobile-number-border{
        border: none;
    }
}

.auth-form-label{
    color: var(--color-dark);
    font-family: var(--g-font-medium);
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.32px;
    margin-bottom: 6px;

    @media (max-width: 767px){
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.28px;
    }
}

.auth-form-input{
    border-radius: 14px;
    border: none;
    color: var(--color-dark-disabled);
    font-family: var(--sf-pro-medium);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.28px;
    height: 48px;

    @media (max-width: 767px){
        padding: 6px 13px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.28px;
    }
}

.auth-input-border{
    border: 1px solid var(--color-dark-muted);
}

.auth-input-border-radius{
    border-radius: 14px;
}

.auth-btn-submit{
    background-color: var(--color-primary);
    color: var(--color-white);
    text-align: center;
    font-family: var(--sf-pro-medium);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
    border-radius: 100px;
    width: 100%;

    &:hover{
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}
.auth-help-block{
    .notification-text{
        color: var(--success-color) !important;
        font-size: 16px;
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
    .small{
        font-size: 16px;
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}


.auth-checkbox-container{
    @media (max-width: 767px){
        padding: 0px;
    }

    .form-input-label{
        @media (max-width: 767px){
            font-size: 13px;
        }
    }
}

// checkbox color change

html {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: var(--color-primary);
    --mdc-checkbox-selected-focus-icon-color:var(--color-primary) ! important;
    --mdc-checkbox-selected-hover-icon-color:var(--color-primary) ! important;
    --mdc-checkbox-selected-icon-color:var(--color-primary) ! important;
    --mdc-checkbox-selected-pressed-icon-color:var(--color-primary) ! important;
    --mdc-checkbox-unselected-focus-icon-color:var(--color-primary) ! important;
    --mdc-checkbox-unselected-hover-icon-color: var(--color-primary) ! important;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color:var(--color-primary) ! important;
    --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary) ! important;
    --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary) ! important;
    --mdc-checkbox-unselected-focus-state-layer-color: var(--color-primary) ! important;
    --mdc-checkbox-unselected-hover-state-layer-color:var(--color-primary) ! important;
    --mdc-checkbox-unselected-pressed-state-layer-color:var(--color-primary) ! important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: var(--color-primary) !important;
    color: var(--color-primary);
    background-color: var(--color-white) !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color:var(--color-primary) !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: var(--color-primary) !important;
    background-color: transparent;
}