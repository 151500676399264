// subscribe-newsletter ///

.subscribe-newsletter-container{
    padding: 80px;
    border-radius: 64px;
    border: 8px solid #FFF;
    background: rgba(238, 247, 255, 0.20);
    box-shadow: 64px 64px 72.51px 0px rgba(51, 102, 255, 0.05);
    backdrop-filter: blur(25px);

    @media screen and (max-width: 1399px) {
        padding: 32px 48px;
        border-radius: 50px;
        border: 6px solid #FFF;
        background: rgba(238, 247, 255, 0.20);
        box-shadow: 49.404px 49.404px 55.973px 0px rgba(51, 102, 255, 0.05);
        backdrop-filter: blur(19.298246383666992px);
    }

    @media screen and (max-width: 767px) {
        padding: 35px 30px;
        margin-bottom: 24px;
    }


    .subscribe-newsletter-head{
        color: var(--color-dark);
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 80.145px;
        letter-spacing: -2.487px;
        margin-bottom: 40px;

        @media screen and (max-width: 1399px) {
            font-size: 42px;
            line-height: 62px;
            letter-spacing: -1.92px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: 991px) {
            text-align: center;
            margin: auto;
            max-width: 400px;
        }

        @media screen and (max-width: 767px) {
            font-size: 34px;
            line-height: 44px;
            letter-spacing: -1.44px;
            margin-bottom: 20px;
        }
    }

    .subscribe-newsletter-text{
        color: var(--color-dark);
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: -0.276px;
        opacity: 0.7;
        margin-bottom: 0;
        max-width: 350px;

        @media screen and (max-width: 1399px) {
            font-size: 20px;
            line-height: 34.133px;
            letter-spacing: -0.213px;
        }

        @media screen and (max-width: 991px) {
            text-align: center;
            margin-bottom: 20px !important;
            max-width: 450px;
            margin: auto;
        }

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 25.597px;
            letter-spacing: -0.16px;
        }
    }

    .subscribe-input-container{
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .subscribe-input{
            margin-right: 25px;
            border-radius: 11.252px;
            border: 1.407px solid #E7E9ED;
            color: var(--color-dark);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 44.218px;
            letter-spacing: -0.276px;
            height: 60px;

            @media screen and (max-width: 1399px) {
                font-size: 18px;
                line-height: 34.133px;
                letter-spacing: -0.213px;
                height: 50px;
            }

            @media screen and (max-width: 767px) {
                margin-right: 0px;
                margin-bottom: 24px;
                font-size: 16px;
                height: 45px;
            }
        }

        .subscribe-input-btn{
            border-radius: 11.252px;
            background: var(--color-primary);
            color: var(--color-white);
            display: flex;
            flex-shrink: 0;
            gap: 20px;
            align-items: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.276px;
            height: 60px;

            @media screen and (max-width: 1399px) {
                border-radius: 8.686px;
                font-size: 20px;
                line-height: normal;
                letter-spacing: -0.213px;
                height: 50px;
                gap: 10px;
            }

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: normal;
                letter-spacing: -0.213px;
                height: 45px;
            }

            svg{
                width: 20px;

                @media screen and (max-width: 1399px) {
                    width: 10px;
                }
            }
        }
    }
}



// fqa container ///////

.faq-container{
    margin-top: 80px;
    margin-bottom: 80px;
    
    .faq-head-container{
        text-align: center;
        position: relative;
        width: 200px;
        margin: auto;
        margin-bottom: 50px;

        .faq-head{
            color: var(--color-dark);
            font-size: 52px;
            font-style: normal;
            font-weight: 400;
            line-height: 29.692px;

            @media screen and (max-width: 1399px) {
                font-size: 48px;
                line-height: 52px;
            }

        }

        .faq-head-svg{
            position: absolute;
            top: -86px;
            width: 150px;
            left: -20px;
        }
    }
}




// easy-get-connected-head ///////////

.easy-get-connected{
    margin-bottom: 55px;

    @media screen and (max-width: 767px) {
        margin-bottom: 25px;
    }

    .easy-get-connected-head{
        color: var(--color-dark);
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 80.145px;
        letter-spacing: -2.487px;
        margin-bottom: 50px;


        @media screen and (max-width: 1399px) {
            font-size: 40px;
            line-height: 80px;
            letter-spacing: -2.487px;
            margin-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -1.44px;
            max-width: 240px;
            margin: auto;
            margin-bottom: 25px;
        }
    }

    .easy-get-connected-card{
        border-radius: 30px;
        border: 1.5px solid #E6E5F4;
        background: #FFF;
        box-shadow: 52.281px 44.439px 72.51px 0px rgba(51, 102, 255, 0.05);
        padding: 65px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        min-height: 420px;

        @media screen and (max-width: 1399px) {
            padding: 36px 40px;
            min-height: 345px;
        }

        @media screen and (max-width: 1199px) {
            padding: 25px 26px;
            min-height: 345px;
        }

        @media screen and (max-width: 767px) {
            min-height: 275px;
        }

        .easy-get-connected-img{
            width: 90px;
            margin-bottom: 40px;

            @media screen and (max-width: 1399px) {
                width: 80px;
                margin-bottom: 20px;
            }
        }

        .easy-get-connected-head-text{
            color: var(--color-dark);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: -0.30px;

            @media screen and (max-width: 1399px) {
                font-size: 22px;
                line-height: 35px;
                letter-spacing: -0.48px;
                margin-bottom: 13px;
            }
        }

        .easy-get-connected-text{
            color: var(--color-dark);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 126%;
            letter-spacing: -0.12px;
            opacity: 0.7;
            margin-bottom: 0;
        }
    }
}

// app-store-list-flex /////////////

.app-store-list-flex{
    display: flex;
    gap: 20px;

    @media screen and (max-width: 991px) {
        margin-top: 60px;
        align-self: center;
        justify-content: center;
    }

    .app-store-list-flex-inner{
        img{
            width: 180px;

            @media screen and (max-width: 767px) {
                width: 120px;
            }
        }
    }
}

.d-app-store-web{
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.d-app-store-tab{
    @media screen and (min-width: 991px) {
        display: none;
    }
}



// ready-to-travel-container //////

.ready-to-travel-container{
    border-radius: 55px;
    border: 6.953px solid #FFF;
    background: rgba(238, 247, 255, 0.20);
    box-shadow: 55.622px 55.622px 63.017px 0px rgba(51, 102, 255, 0.05);
    backdrop-filter: blur(21.72px);
    overflow: hidden;
    .ready-to-travel-inner-left-container{
        padding: 100px 50px 75px 75px;
        align-self: center;

        @media screen and (max-width: 991px) {
            padding: 50px;
            padding-bottom: 0;
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            padding: 28px;
        }

        .ready-to-travel-inner-left-head{
            color: var(--color-dark);
            font-size: 54px;
            font-style: normal;
            font-weight: 400;
            line-height: 69.653px;
            letter-spacing: -2.162px;
            max-width: 480px;
            margin-bottom: 8px;

            @media screen and (max-width: 1399px) {
                font-size: 40px;
                line-height: 46.395px;
                letter-spacing: -1.44px;
                margin-bottom: 10px;
                max-width: 385px;
            }

            @media screen and (max-width: 767px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 44px;
                letter-spacing: -1.44px;
                margin-bottom: 20px;
            }
        }

        .ready-to-travel-inner-left-subhead{
            color: var(--color-dark);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.24px;
            opacity: 0.7;
            max-width: 420px;
            margin-bottom: 80px;

            @media screen and (max-width: 1399px) {
                font-size: 20px;
                line-height: 25.597px;
                letter-spacing: -0.16px;
                margin-bottom: 22px;
            }

            @media screen and (max-width: 767px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.597px;
                letter-spacing: -0.16px;
                margin-bottom: 0;
            }
        }
    }

    .ready-to-travel-img-container{
        max-width: 450px;

        @media screen and (max-width: 1199px) {
            max-width: 370px;
            margin-left: auto;
        }

        @media screen and (max-width: 991px) {
            max-width: 510px;
        }

        .ready-to-travel-img{
            width: 100%;
            position: relative;
            right: -180px;

            @media screen and (max-width: 1399px) {
                right: -80px;
            }

            @media screen and (max-width: 1199px) {
                right: 0;
            }
        }
    }
}



// FAQ Container /////////////////////////////

.faq-container{
    .accordion {
        &-item {
            
            width: 100%;
            gap: 19px;
            border-radius: 15px !important;
            // border: 1px solid rgba(0, 0, 0, 0.17);
            border: none;
            background: var(--color-white);

            padding: 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0 !important;
            padding-bottom: 15px;
            &:not(:first-child){
                margin-top: 20px !important;
            }

            .accordion-header{
                .accordion-header-text{
                    color: var(--color-dark);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400 !important;
                    line-height: 26.401px;

                    @media screen and (max-width: 1399px) {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        line-height: 19.725px;
                    }
                }
            }
        }
        &-button {

            background: transparent !important;
            padding: unset !important;
            color: var(--color-white);
            font-family: var(--sf-pro-regular);
            font-size: 18px;
            line-height: normal;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 0;

            svg{
            //  height: 1.5vw;
            //  width: 1.5vw;

            width: 26px;
            height: 26px;
            
            }
            &:not(.collapsed) {
                background-color: var(--bs-accordion-active-bg);
                // border-bottom: 1px solid rgba(0, 0, 0, 0.50); !important;
                box-shadow: unset !important;
                padding-bottom: 10px !important;

                .accordion-header-text{
                    color: var(--color-primary);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26.401px;
                }
            }
            
            &:focus {
                box-shadow: none !important;

            }

            &::after {
                content: none !important;
            }

            .accordion-header-text-minus{
                display: none;
            }
            .accordion-header-text-plus{
                display: block;
            }
        }

        .accordion-button.collapsed{
            .accordion-header-text-minus{
                display: block;
            }
            .accordion-header-text-plus{
                display: none;
            }
        }
        &-body {
            // font-size: 1.042vw;
            // line-height: 170%;
            // padding-bottom: 0 !important;

            padding: var(--bs-accordion-body-padding-y) 0 0 !important;

            color: var(--color-dark);
            // font-family: var(--sf-pro-regular);
            font-size: 17px;
            line-height: 26px;
            opacity: 0.7;

            @media screen and (max-width: 1399px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
            }

            @media screen and (max-width: 767px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21.128px;
            }
        }
    }
}


// help page ////////////////////

.help-page{
    .g-page-title-section .g-page-main-title {
        span{
            @media (max-width: 767px){
                font-size: 22px !important;
                line-height: 35px;
            }
        }
    }
}


.m-app-page-background{
    padding: 30px;
}